import {useMutation, useQuery, useQueryClient} from "react-query";
import {TFunction, useTranslation} from "react-i18next";
import {delay} from "lodash";
import {useSnackbar} from "notistack";
import { Group, GroupData, Site, SiteData } from "store/types";
import BaseService from "services/api/BaseService";
import { GroupeUrls } from '../../../services/api/urls';
import { SiteUrls } from 'services/api/urls';


const get_detail_key = (uuid: string) => ['site_details', uuid];
export type SiteCrudType = ['sites', number, string, string]
export const payload_sites_key = ['sites_current_key'];
export const default_sites_key: SiteCrudType = ['sites', 1, '', 'date_added'];

export type GroupCrudType = ['groupes', number, string, string]
export const payload_groupes_key = ['groupes_current_key'];
export const default_groupess_key: GroupCrudType = ['groupes', 1, '', 'date_added'];

export const useGroupes = (page: number, keyword: string,) => {
    const {t} = useTranslation();
    const queryClient = useQueryClient();
    const current_key = ['groupes', page, keyword];
    queryClient.setQueryData(payload_groupes_key, current_key);

    return useQuery<GroupData, Error>(
        current_key,
        async ({pageParam, queryKey: [key, page]}) => {
            let data: any;
            try {
                const response = await BaseService.getRequest(
                    GroupeUrls.READ_GROUPE({
                        page: (page as number),
                        keyword: (keyword as string),
                        per_page: 9,
                        // order_field: 'date_added'
                    }),
                    true
                )
                data = await response.json();
                if ([200, 201].includes(response.status)) {
                    return data;
                }
            } catch (e: any) {
                throw new Error(t('shared.internet_connexion_error'))
            }
            throw new Error(data?.message || data?.detail || t('shared.internet_connexion_error'));
        },
    )
}

const default_groupe: GroupData = {
    pages: 1,
    data: [],
    current_page: 1,
    total: 10

}

export const useCreateGroupeMutation = (
    setLoading: (loading: boolean) => void,
    onSuccess: () => void
) => {

    const {enqueueSnackbar} = useSnackbar();
    const queryClient = useQueryClient();
    const {t} = useTranslation();

    return useMutation(
        async (values: any) => {
            setLoading(true);
            let data;
            try {
                const response = await BaseService.postRequest(GroupeUrls.CREATE_GROUPE, values, true);
                data = await response.json();
                if ([200, 201].includes(response.status)) {
                    return data;
                }
            } catch (e: any) {
                throw new Error(t('shared.internet_connexion_error'))
            }
            throw new Error(data?.message || data?.detail || t('shared.internet_connexion_error'));
        },
        {
            onError: (error: Error) => {
                enqueueSnackbar(error.message, {
                    variant: 'warning'
                })
            },
            onSuccess: (data: Group) => {

                queryClient.setQueryData<GroupData>(['groupes', 1, ''], (old) => {
                    const groupes = old || default_groupe;

                    return {
                        ...groupes,
                        data: [data, ...(groupes.data || []), ]
                    }
                });

                enqueueSnackbar(t('group_add_success'), {
                    variant: 'success'
                });

                onSuccess();
            },
            onSettled: () => {
                setLoading(false)
            }
        }
    )

}



export const useUpdateGroupMutation = (
    setLoading: (loading: boolean) => void,
    onSuccess: () => void
) => {

    const {enqueueSnackbar} = useSnackbar();
    const queryClient = useQueryClient();
    const {t} = useTranslation();

    return useMutation(
        async (values: any) => {
            setLoading(true);
            let data;
            try {
                const response = await BaseService.putRequest(GroupeUrls.UPDATE_GROUPE, values, true);
                data = await response.json();
                if ([200, 201].includes(response.status)) {
                    return data;
                }
            } catch (e: any) {
                throw new Error(t('shared.internet_connexion_error'))
            }
            throw new Error(data?.message || data?.detail || t('shared.internet_connexion_error'));
        },
        {
            onError: (error: Error) => {
                enqueueSnackbar(error.message, {
                    variant: 'warning'
                })
            },
            onSuccess: (data: Group) => {

                queryClient.setQueryData<GroupData>(['groupes', 1, ''], (old) => {
                    const groupes = old || default_groupe;

                    return {
                        ...groupes,
                        data: [...(old?.data || [])].map(groupe => groupe.uuid === data.uuid ? data : groupe)
                    }
                });

                enqueueSnackbar(t('group_update_success'), {
                    variant: 'success'
                });

                onSuccess();
            },
            onSettled: () => {
                setLoading(false)
            }
        }
    )
}

export const useDeleteGroupMutation = (
    setLoading: (loading: boolean) => void,
    onSuccess: () => void
) => {

    const {enqueueSnackbar} = useSnackbar();
    const queryClient = useQueryClient();
    const {t} = useTranslation();
    let message:string = ''

    return useMutation(
        async (uuid: string) => {
            setLoading(true);
            let data;
            try {
                const response = await BaseService.deleteRequest(GroupeUrls.DELETE_GROUPE(uuid), {}, true);
                data = await response.json();
                message = data.message
                if ([200, 201].includes(response.status)) {
                    return {uuid};
                }
            } catch (e: any) {
                throw new Error(t('shared.internet_connexion_error'))
            }
            throw new Error(data?.message || data?.detail || t('shared.internet_connexion_error'));
        },
        {
            onError: (error: Error) => {
                enqueueSnackbar(error.message, {
                    variant: 'warning'
                })
            },
            onSuccess: (data: { uuid: string }) => {

                queryClient.setQueryData<GroupData>(['groupes', 1, ''], (old) => {
                    const groupes = old || default_groupe;

                    return {
                        ...groupes,
                        data: [...(old?.data || [])].filter(groupe => groupe.uuid !== data.uuid)
                    }
                });

                enqueueSnackbar(message , {
                    variant: 'success'
                });

                onSuccess();
            },
            onSettled: () => {
                setLoading(false)
            }
        }
    )
}


//site hooks

export const useSites = (page: number, keyword: string,) => {
    const {t} = useTranslation();
    const queryClient = useQueryClient();
    const current_key = ['sites', page, keyword];
    queryClient.setQueryData(payload_sites_key, current_key);


    return useQuery<SiteData, Error>(
        ['sites', page, keyword],
        async ({pageParam, queryKey: [key, page]}) => {
            let data: any;
            try {
                const response = await BaseService.getRequest(
                    SiteUrls.READ_SITE({
                        page: (page as number),
                        keyword: (keyword as string),
                        per_page: 9,
                        // order_field: 'date_added'
                    }),
                    true
                )
                data = await response.json();
                if ([200, 201].includes(response.status)) {
                    return data;
                }
            } catch (e: any) {
                throw new Error(t('shared.internet_connexion_error'))
            }
            throw new Error(data?.message || data?.detail || t('shared.internet_connexion_error'));
        },
    )
}

const default_site: SiteData = {
    pages: 1,
    data: [],
    current_page: 1,
    total: 10

}

export const useCreateSiteMutation = (
    setLoading: (loading: boolean) => void,
    group_uuid:string,
    onSuccess: () => void
) => {

    const {enqueueSnackbar} = useSnackbar();
    const queryClient = useQueryClient();
    const {t} = useTranslation();

    return useMutation(
        async (values: any) => {
            setLoading(true);
            let data;
            try {
                const response = await BaseService.postRequest(SiteUrls.CREATE_SITE, values, true);
                data = await response.json();
                if ([200, 201].includes(response.status)) {
                    return data;
                }
            } catch (e: any) {
                throw new Error(t('shared.internet_connexion_error'))
            }
            throw new Error(data?.message || data?.detail || t('shared.internet_connexion_error'));
        },
        {
            onError: (error: Error) => {
                enqueueSnackbar(error.message, {
                    variant: 'warning'
                })
            },
            onSuccess: (data: Site) => {
                const key = queryClient.getQueryData<GroupCrudType>(payload_groupes_key) || default_sites_key;

                queryClient.setQueryData<GroupData | undefined >(key, (old) => {
                    const groupes = old || default_groupe  ;

                    const indexObject = groupes.data.findIndex((group) => group.uuid == group_uuid)
                    groupes?.data[indexObject]?.sites?.push(data)
                    return {
                        ...groupes,
                    }
                });

                enqueueSnackbar(t('site_add_success'), {
                    variant: 'success'
                });

                onSuccess();
            },
            onSettled: () => {
                setLoading(false)
            }
        }
    )

}



export const useUpdateSiteMutation = (
    setLoading: (loading: boolean) => void,
    group_uuid:string,
    onSuccess: () => void
) => {

    const {enqueueSnackbar} = useSnackbar();
    const queryClient = useQueryClient();
    const {t} = useTranslation();

    return useMutation(
        async (values: any) => {
            setLoading(true);
            let data;
            try {
                const response = await BaseService.putRequest(SiteUrls.UPDATE_SITE, values, true);
                data = await response.json();
                if ([200, 201].includes(response.status)) {
                    return data;
                }
            } catch (e: any) {
                throw new Error(t('shared.internet_connexion_error'))
            }
            throw new Error(data?.message || data?.detail || t('shared.internet_connexion_error'));
        },
        {
            onError: (error: Error) => {
                enqueueSnackbar(error.message, {
                    variant: 'warning'
                })
            },
            onSuccess: (data: Site) => {
                const site = queryClient.getQueryData<Site>(get_detail_key(data.uuid ?? '-'));
                queryClient.removeQueries(get_detail_key(data.uuid ?? ''), { exact: true })
                if (site) {
                    queryClient.setQueryData<Site>(get_detail_key(data.uuid ?? ''), {...site, ...data});
                    const key = queryClient.getQueryData<GroupCrudType>(payload_groupes_key) || default_sites_key;

                    queryClient.setQueryData<GroupData | undefined >(key, (old) => {
                        const groupes = old?.data ?? []  ;

                        if(!old)return old;
                        const indexObject = groupes.findIndex((group) => group.uuid == group_uuid)
                        const newGroupes = groupes[indexObject]?.sites?.map(p =>
                            p.uuid === data.uuid
                              ? p = data
                              : p
                          );
                        groupes[indexObject].sites = newGroupes
                        return {
                            ...old,
                            data: groupes
                        }
                    });
                }
                
                enqueueSnackbar(t('site_update_success'), {
                    variant: 'success'
                });

                onSuccess();
            },
            onSettled: () => {
                setLoading(false)
            }
        }
    )
}

export const useDeleteSiteMutation = (
    setLoading: (loading: boolean) => void, 
    group_uuid:string,
    onSuccess: () => void
) => {
    const {enqueueSnackbar} = useSnackbar();
    const queryClient = useQueryClient();
    const {t} = useTranslation();
    let message:string = ''


    return useMutation(
        async (uuid: string) => {
            setLoading(true);
            let data;
            try {
                const response = await BaseService.deleteRequest(SiteUrls.DELETE_SITE(uuid), {}, true);
                data = await response.json();
                message = data.message
                if ([200, 201].includes(response.status)) {
                    return {uuid};
                }
            } catch (e: any) {
                throw new Error(t('shared.internet_connexion_error'))
            }
            throw new Error(data?.message || data?.detail || t('shared.internet_connexion_error'));
        },
        {
            onError: (error: Error) => {
                enqueueSnackbar(error.message, {
                    variant: 'warning'
                })
            },
            onSuccess: (data: { uuid: string }) => {
                const site = queryClient.getQueryData<Site>(get_detail_key(data.uuid ?? '-'));
                queryClient.removeQueries(get_detail_key(data.uuid ?? ''), { exact: true })
                if (site) {
                    // queryClient.setQueryData<Site>(get_detail_key(data.uuid ?? ''), {...site, ...data});
                    const key = queryClient.getQueryData<GroupCrudType>(payload_groupes_key) || default_sites_key;

                    queryClient.setQueryData<GroupData | undefined >(key, (old) => {
                        const groupes = old?.data ?? []  ;

                        if(!old)return old;
                        const indexObject = groupes.findIndex((group) => group.uuid == group_uuid)
                        const findIndexSite = groupes[indexObject]?.sites?.findIndex(site => site.uuid == data.uuid)

                        if (findIndexSite == undefined) return old
                        groupes[indexObject].sites?.splice(findIndexSite,1)
                        return {
                            ...old,
                            data: groupes
                        }
                    });
                }

                enqueueSnackbar(message, {
                    variant: 'success'
                });

                onSuccess();
            },
            onSettled: () => {
                setLoading(false)
            }
        }
    )
}


export const useSiteDetails = (uuid: string) => {
    const {t} = useTranslation();

    return useQuery<Site, Error>(
        get_detail_key(uuid),
        async ({pageParam, queryKey: [key, page]}) => {
            let data: any;
            try {
                const response = await BaseService.getRequest(
                    SiteUrls.READ_SITE_UUID(uuid),
                    true
                )
                data = await response.json();
                if ([200, 201].includes(response.status)) {
                    return data;
                }
            } catch (e: any) {
                throw new Error(t('shared.internet_connexion_error'))
            }
            throw new Error(data?.message || data?.detail || t('shared.internet_connexion_error'));
        },
    )
}

