import { Pagination, UserPagination } from "store/types";
import {
    FetchGrantUsersPayload,
    FilterParams,
    getLockedUserEndpoint, lockUserEndpoint,
    ProjectPagination,
    TaskHomePayload
} from "../../store/types/ProjectType";
import { SafeZoneFilesPayload } from "../../store/types/SafeZoneType";
import config from '../../config';

// export const prefixer = 'http://192.168.83.12:8500/api/v1/';
export const prefixer = config.api_url;
// export const prefixer = 'https://api.develop.suitsmen.fr/api/v1/';
// export const prefixer = 'http://api.develop.suitsmen.fr:8000/api/v1/';

export const AuthUrls = {
    READ_TAGS: `${prefixer}auth/login`,
    UPDATE_USER_INFO: `${prefixer}auth/update`,
    UPDATE_PASSWORD: `${prefixer}auth/update-password`,
    LOGIN_USER: `${prefixer}auth/login`,
    LOGIN_USER_OTP: `${prefixer}auth/login/opt`,
    LOGIN_AZURE_AD_URL: () => `${prefixer}auth/login/azure-ad`,
    CONFORM_LOGIN_USER: (code: string) => `${prefixer}auth/login-validate/${code}`,
    CONFORM_LOGIN_USER_OTP: `${prefixer}auth/opt/login-validate`,
    GET_CONNECTED_INFO: `${prefixer}auth/me`,
    SEND_EMAIL_RESET_PWD: (email: string) => `${prefixer}auth/password-recovery/${email}`,
    RESEND_LOGIN_MAIL: (email: string) => `${prefixer}auth/resend-mail/${email}`,
    SEND_NEW_PWD: `${prefixer}auth/reset-password`,
    USER_CHANGE_PASSWORD: () => `${prefixer}auth/me/password`,
    LOGIN_WITH_AZURE: `${prefixer}auth/login/azure-ad`,
    IMPERSONATE:(uuid:string) => `${prefixer}auth/login/${uuid}`
};

export const SafeZoneUrl = {
    SIGN_IN: `${prefixer}safe/code`,
    LOGIN: `${prefixer}safe/auth`,
    ASKED_SAFE_ZONE: `${prefixer}safe/ask/access`,
    ACCEPTED_SAFE_ZONE: (uuid: string, give_authorization: string) => `${prefixer}safe/ask/access?user_uuid=${uuid}&give_authorization=${give_authorization}`,
    UPDATE_CODE_OR_QUESTION: `${prefixer}safe/reset-code`,
    UPDATE_CODE_OR_QUESTION_VALIDATE: `${prefixer}/safe/validate/reset-code`,
    FETCH_FOLDERS: (token: string) => `${prefixer}safe/settings?token=${token}`,
    ADD_FILE: `${prefixer}safe/settings/files`,
    UPDATE_FILE: `${prefixer}safe/settings/files`,
    DELETE_FILE: (uuid: string, token: string) => `${prefixer}safe/settings/files/${uuid}?token=${token}`,
    UPDATE_FOLDER: (token: string) => `${prefixer}safe/settings?token=${token}`,
    GET_FILES: (p: SafeZoneFilesPayload) => `${prefixer}safe/settings/files?token=${p.safe_token}&page=${p.page}&per_page=20&safe_setting_uuid=${p.safe_setting_uuid}&order=desc&order_field=date_added&${p.keyword ? `&keyword=${p.keyword}` : ''}`
}

export const LogsUrl = {
    READ_LOGS: (p: Pagination) => `${prefixer}logs?page=${p.page || 1}&keyword=${p.keyword || ''}${p.start_date != '' ? `&start_date=${p.start_date}` : ''}${p.end_date != '' ? `&end_date=${p.end_date}` : ''}&per_page=${p.per_page || 12}&order=desc`,
}

export const GroupeUrls = {
    CREATE_GROUPE: `${prefixer}groups`,
    READ_GROUPE: (p: Pagination) => `${prefixer}groups?page=${p.page || 1}&keyword=${p.keyword || ''}&per_page=${p.per_page || 12}&order=desc`,
    DELETE_GROUPE: (uuid: string) => `${prefixer}groups/${uuid}`,
    UPDATE_GROUPE: `${prefixer}groups`,
}


export const SiteUrls = {
    CREATE_SITE: `${prefixer}groups/site`,
    READ_SITE: (p: Pagination) => `${prefixer}groups/list/site?page=${p.page || 1}&keyword=${p.keyword || ''}&per_page=${p.per_page || 12}&order=desc`,
    DELETE_SITE: (uuid: string) => `${prefixer}groups/${uuid}/site`,
    READ_SITE_UUID: (uuid: string) => `${prefixer}groups/${uuid}/site`,
    UPDATE_SITE: `${prefixer}groups/site`,
}

export const UserUrls = {
    LIST_USERS: (p: UserPagination) => `${prefixer}users?page=${p.page || 1}&per_page=${p.per_page || 9}&keyword=${p.keyword || ''}&order=${p.order ?? 'desc'}&order_field=${p.order_field || 'date_added'}${typeof p.mandant !== 'undefined' ? `&mandant=${p.mandant}` : ''}${typeof p.show_for_project_visibility !== 'undefined' ? `&show_for_project_visibility=${p.show_for_project_visibility}` : ''}${typeof p.safe_access !== 'undefined' ? `&safe_access=${p.safe_access}` : ''}${typeof p.client_uuid !== 'undefined' ? `&client_uuid=${p.client_uuid}` : ''}${typeof p.no_role !== "undefined" ? `&no_role=${p.no_role}` : ''}${typeof p.unactive !== "undefined" ? `&unactive=${p.unactive}` : ''}`,
    LIST_USERS_WORKER: (p: UserPagination) => `${prefixer}users?page=${p.page || 1}&per_page=${p.per_page || 9}&keyword=${p.keyword || ''}&order=desc&order_field=${p.order_field || 'date_added'}&role_uuids=b306d48e-a0e1-4026-bd54-85c10eba07e2&role_uuids=f8028536-2354-4105-ae3e-7a38d88a50cb`,
    ADD_USER: `${prefixer}users/new`,
    UPDATE_USER: `${prefixer}users`,
    DETAILS_USER: (uuid: string) => `${prefixer}users/access/${uuid}`,
    DELETE_USER: (uuid: string) => `${prefixer}users/${uuid}`,
    UPDATE_USER_AVATAR: `${prefixer}users/avatar`,
    GET_USER_AUTHORISATION: (p: UserPagination) => `${prefixer}users/site?page=${p.page || 1}${p.keyword != '' ? `&keyword=${p.keyword}` : ''}&per_page=${p.per_page || 12}&order=desc&order_field=${p.order_field || 'date_added'}${p.client_uuid != '' ? `&client_uuid=${p.client_uuid}` : ''}${p.project_uuid != '' ? `&project_uuid=${p.project_uuid}` : ''}${p.task_group_uuid != '' ? `&task_group_uuid=${p.task_group_uuid}` : ''}${p.task_uuid != '' ? `&task_uuid=${p.task_uuid}` : ''}${p.filer != '' ?`&filter=${p.filer}`: ''} `,
    SET_DEFAULT_REMINDER: `${prefixer}users/reminders`,
    GET_TASKS_AUTHORISATION: (client_uuid: string, mandant_uuid: string) => `${prefixer}users/site/authorization/${mandant_uuid}?client_uuid=${client_uuid}`,
    GROUP_TASK_VISIBILITY:(endUrl:string)=>`${prefixer}users/groups-tasks/visibility?${endUrl}`,
    ADD_TASK_GROUP_VISIBILITY_MANDANT:`${prefixer}tasks/visibility/add-remove`,
    ADD_TASK_GROUP_VISIBILITY_EMPLOYEE:`${prefixer}tasks/visibility/add-remove/employee`,
    GET_USER_TASK_DONE:(uuid:string) => `${prefixer}tasks/user-tasks?project_uuid=${uuid} `,
    GET_ALL_USER:(p:UserPagination) => `${prefixer}users/${p.task_uuid}/all?project_uuid=${p.project_uuid}&${p.keyword != '' ? `&keyword=${p.keyword}` : ''}`
}
export const ClientUrls = {
    CREATE_CLIENT: `${prefixer}clients`,
    HOME_TASK: `${prefixer}tasks/home`,
    HOME_TASK_CALENDAR: (payload: TaskHomePayload) => `${prefixer}tasks/calendar?start_date=${payload.start_date}&end_date=${payload.end_date}`,
    READ_CLIENT: (p: UserPagination) => `${prefixer}clients?page=${p.page || 1}&keyword=${p.keyword || ''}&per_page=${p.per_page || 12}&order=${p.order || 'desc'}&order_field=${p.order_field || 'date_added'}${p.favorite ? '&favorites=true' : ''}`,
    READ_CLIENT_HOME: (p: UserPagination) => `${prefixer}clients/home?page=${p.page || 1}&keyword=${p.keyword || ''}&per_page=${p.per_page || 12}&order=${p.order || 'desc'}&order_field=${p.order_field || 'date_added'}${p.favorite ? '&favorites=true' : ''}${p.invitation ? `&invitation=${p.invitation} `:''}`,
    READ_CLIENT_HOME2: (p: UserPagination) => `${prefixer}clients/home2?page=${p.page || 1}&keyword=${p.keyword || ''}&per_page=${p.per_page || 12}&order=${p.order || 'desc'}&order_field=${p.order_field || 'date_added'}${p.favorite ? '&favorites=true' : ''}${p.invitation ? `&invitation=${p.invitation} `:''}`,
    DELETE_CLIENT: (uuid: string) => `${prefixer}clients/${uuid}`,
    READ_CLIENT_UUID: (uuid: string) => `${prefixer}clients/${uuid}`,
    UPDATE_CLIENT: `${prefixer}clients`,
    DELETE_USER_VISIBLITY_URL: (client_uuid: string, user_uuid: string,) => `${prefixer}clients/${client_uuid}/${user_uuid}`,
    ADD_USER_VISIBLITY_URL: (client_uuid: string) => `${prefixer}clients/${client_uuid}`,
    GET_CLIENT_PROJECTS:(uuid:string, isInvitation?: boolean) =>`${prefixer}clients/home/projects/${uuid}?invitation=${isInvitation}`,

    READ_USER_CLIENT_UUID: (p: UserPagination, uuid: string) => `${prefixer}clients/users/${uuid}`,
    CREATE_USER_CLIENT: (uuid: string) => `${prefixer}clients/users/${uuid}`,
    ADD_EXISTING_USER_TO_CLIENT: (uuid: string) => `${prefixer}clients/add/users/${uuid}`,
    UPDATE_USER_CLIENT: (uuid: string) => `${prefixer}clients/users/${uuid}`,
    DELETE_USER_CLIENT: (uuid: string, user_uuid: string) => `${prefixer}clients/users/${uuid}/${user_uuid}`,
    GET_CLIENT_FOR_FILTER: `${prefixer}clients/site`,
    ADD_CLIENT_IN_FAVORITES: `${prefixer}clients/favorites`,
    DELETE_CLIENT_IN_FAVORITES: `${prefixer}clients/favorites`,
    GET_DATEV_CLIENT_LIST:(keyword:string) =>  `${prefixer}datev/clients?keyword=${keyword}`,
    ADD_DATEV_CLIENT:`${prefixer}datev/client`,
    INVATATION_USER_TO_CLIENT:(client_uuid:string,email:string) => `${prefixer}clients/${client_uuid}/invite/${email}`

}

export const ProjectUrls = {
    LIST_PROJECT: (p: ProjectPagination) => `${prefixer}projects?page=${p.page || 1}&per_page=${p.per_page || 9}&keyword=${p.keyword || ''}&order=desc`,
    ADD_PROJECT: `${prefixer}projects`,
    UPDATE_PROJECT: `${prefixer}projects`,
    UPDATE_PROJECT_STATUS: `${prefixer}projects/status`,
    DELETE_PROJECT: (uuid: string) => `${prefixer}projects/${uuid}`,
    DETAIL_PROJECT: (uuid: string) => `${prefixer}projects/${uuid}`,
    USER_TOGGLE_VISIBILITY: (project_uuid: string) => `${prefixer}projects/visibility/${project_uuid}`,
    USER_TOGGLE_VISIBILITY_2: (project_uuid: string) => `${prefixer}projects/visibility/${project_uuid}/add-remove`,
    USER_TOGGLE_VISIBILITY_EMPLOYEE: (project_uuid: string) => `${prefixer}projects/visibility/${project_uuid}/add-remove/employee`,
    OVERVIEW_PROJECT: (uuid: string) => `${prefixer}tasks/overviews/${uuid}`,
    CLIENT_PROJECT: (uuid: string) => `${prefixer}projects/site/${uuid}`,
    UPDATE_STATUS: `${prefixer}projects/status`,
    CLIENT_PROJECTS: (p: ProjectPagination, client_uuid: string) => `${prefixer}projects/site/${client_uuid}?page=${p.page || 1}&per_page=${p.per_page || 9}&keyword=${p.keyword}`,
    ADD_PROJECTS_IN_FAVORIES: `${prefixer}projects/favorites`,
    DELETE_PROJECTS_IN_FAVORITES: `${prefixer}projects/favorites`,
    ROLLFORWARD:(project_uuid:string) => `${prefixer}projects/${project_uuid}/duplicate`,
    INVITE_USER_TO_PROJECT:(project_uuid:string,email:string)=>`${prefixer}projects/${project_uuid}/invite/${email}`,
    FETCH_INVITATION_LIST:(filter:string, project_name?:string,user?:string,status?:string) =>`${prefixer}projects/invitations?filter=${filter}${project_name ? `&project_name=${project_name}`:''}${user ? `&user=${user}`:''}${status ? `&status=${status}`:''}`,
    INVITATION_UPDATE_STATUS:(invitation_uuid:string,statut:string)=>`${prefixer}projects/invitation/${invitation_uuid}?status=${statut}`
}

export const TemplateUrls = {
    LIST_TEMPLATE: (p: ProjectPagination) => `${prefixer}templates?page=${p.page || 1}&per_page=${p.per_page || 20}&order=desc&my_template=${p.my_template || false}&is_default=${p.is_default || false}&order_field=${p.order_field || 'date_added'}`,
    ADD_GENERAL_TEMPLATE: (project_uuid: String) => `${prefixer}projects/${project_uuid}/generate/template`,
    ADD_TEMPLATE: `${prefixer}templates`,
    UPDATE_TEMPLATE: `${prefixer}templates`,
    UPDATE_TEMPLATE_STATUS: `${prefixer}templates/status`,
    DELETE_TEMPLATE: (uuid: string) => `${prefixer}templates/${uuid}`,
    DETAIL_TEMPLATE: (uuid: string) => `${prefixer}templates/${uuid}`,
    DELETE_TEMPLATE_TASKS_GROUP:`${prefixer}templates/task-groups`,
    DELETE_TEMPLATE_TASKS:`${prefixer}templates/tasks-templates`
}

export const TemplateTaskGroupUrls = {
    LIST_TEMPLATE_TASK_GROUP: (p: ProjectPagination) => `${prefixer}templates/task-group?page=${p.page || 1}&per_page=${p.per_page || 9}&order=desc&my_template =${p.my_template || false}&is_default=${p.is_default || false}&order_field=${p.order_field || 'date_added'}`,
    ADD_TEMPLATE_TASK_GROUP: `${prefixer}templates/task-group`,
    UPDATE_TEMPLATE_TASK_GROUP: `${prefixer}templates/task-group`,
    DELETE_TEMPLATE_TASK_GROUP: (uuid: string) => `${prefixer}templates/task-group/${uuid}`,
    DETAIL_TEMPLATE_TASK_GROUP: (uuid: string) => `${prefixer}templates/task-group/${uuid}`,
}

export const TemplateTaskUrls = {
    LIST_TEMPLATE_TASK: (p: ProjectPagination) => `${prefixer}templates/task-template?page=${p.page || 1}&per_page=${p.per_page || 9}&order=desc&my_template =${p.my_template || false}&is_default=${p.is_default || false}&order_field=${p.order_field || 'date_added'}`,
    ADD_TEMPLATE_TASK: `${prefixer}templates/task-template`,
    UPDATE_TEMPLATE_TASK: `${prefixer}templates/task-template`,
    DELETE_TEMPLATE_TASK: (uuid: string) => `${prefixer}templates/task-template/${uuid}`,
    DETAIL_TEMPLATE_TASK: (uuid: string) => `${prefixer}templates/task-template/${uuid}`,
    LOAD_TEMPLATE: `${prefixer}templates/load`
}


export const GlobalUrl = {
    GET_GLOBAL_DATA: `${prefixer}users/default/data`,
    VESRIONNING_UPDATE: `${prefixer}logs/app/updates`
};


export const NotificationUrl = {
    FETCH_NOTIFICATION: `${prefixer}notifications`,
    DELETE_NOTIFICATION: `${prefixer}notifications`,
    READ_NOTIFICATION: (id: number) => `${prefixer}notifications/${id}`,
};
export const DocumentUrl = {
    FETCH_DOCUMENTS: (p: ProjectPagination) => `${prefixer}storages/documents?page=${p.page || 1}&per_page=${p.per_page || 9}&order=desc`,
    FETCH_DOCUMENTS_PROJECT: (p: ProjectPagination,uuid:string) => `${prefixer}tasks/document/${uuid}?${p?.keyword != null && p?.keyword?.length ? `keyword=${p?.keyword}&` : ''}page=${p.page || 1}&per_page=${p.per_page || 9}&order=desc`,
    FETCH_ALL_VERSION_DOCUMENT:(uuid:string)=>`${prefixer}tasks/document/version/${uuid}`,
    FETCH_RESTORE_VERSION_DOCUMENT:(uuid:string, oldUuid:string)=>`${prefixer}tasks/document/${oldUuid}/version/${uuid}/restore`,
    DELETE_TASK_DOCUMENT_VERSION:(uuid:string)=>`${prefixer}tasks/document/version/${uuid}`,
    ADD_DOCUMENT_DATEV_TO_TASK:(task_uuid:string)=>`${prefixer}datev/document?task_uuid=${task_uuid}`,
    GET_DOCUMENT_DATEV_FOLDER_DATEV:(id:string,p:{top:number,skip:number})=>`${prefixer}datev/document/${id}/structure-items?top=${p.top}&skip=${p.skip}`,
    GET_CLIENT_DATEV_DOCUMENT:(client_uuid:string,p:{top:number,skip:number})=>`${prefixer}datev/documents/${client_uuid}?top=${p.top}&skip=${p.skip}`,

    FETCH_DATEV_STATES: () => `${prefixer}datev/states`,
    FETCH_DATEV_FOLDERS: () => `${prefixer}datev/folders`,
    FETCH_DATEV_REGISTER: () => `${prefixer}datev/register`,

}


export const TaskUrl = {
    GET_TASK_GROUP: (uuid: string) => `${prefixer}tasks/groups?page=1&per_page=200&order=desc&project_uuid=${uuid}&order_field=position`,
    GET_TASK_GROUP_WITH_MASK_PROPRETITY: (uuid: string, isShow: boolean, child_uuid?: string, task_status?: string) =>
        `${prefixer}tasks/groups/project/group?page=1&per_page=200&order=desc&project_uuid=${uuid}&order_field=position&show_with_masked_items=${isShow}${task_status ? `&task_status=${task_status}` : ''}`,
    HIDE_TASK_AND_GROUP: (project_uuid: string, action: string) => `${prefixer}tasks/hide?project_uuid=${project_uuid}&action=${action}`,
    CREATE_TASK_GROUP: `${prefixer}tasks/groups`,
    UPDATE_TASK_GROUP: `${prefixer}tasks/groups`,
    GROUP_ACTION: `${prefixer}tasks/group-actions`,
    DELETE_TASK_GROUP: (uuid: string) => `${prefixer}tasks/groups/${uuid}`,
    GET_TASK_DETAIL: (uuid: string) => `${prefixer}tasks/${uuid}`,
    GET_TASK_DOCUMENTS:(uuid:string) =>`${prefixer}tasks/documents/${uuid}`,

    CREATE_TASK: `${prefixer}tasks`,
    UPDATE_TASK: `${prefixer}tasks`,
    ADD_TASK_COMMENT: `${prefixer}tasks/comment`,
    DELETE_TASK_COMMENT: (uuid:string) => `${prefixer}tasks/comment/${uuid}`,
    DELETE_TASK_DOCUMENT: `${prefixer}tasks/document`,
    DOCUMENT_REQUEST_SIGN: `${prefixer}tasks/document/ask-sign`,
    DOCUMENT_REQUEST_DOWNLOAD_ALL:(uuid:string) => `${prefixer}tasks/project/${uuid}/documents/download`,
    DOCUMENT_APPROVE_SIGN: `${prefixer}tasks/document/approve-sign`,
    SET_REMINDER_TASK: `${prefixer}tasks/reminders`,
    ADD_TASK_DOCUMENT: (payload: any) => `${prefixer}tasks/document?document_uuid=${payload.document_uuid}&status=${payload.status}`,
    DELETE_TASK: (uuid: string, deleteTaskGroup: boolean) => `${prefixer}tasks/${uuid}?delete_task_group=${deleteTaskGroup}`,
    UPDATE_STATUS_TASK: (uuid: string, status: string) => `${prefixer}tasks/status?task_uuid=${uuid}&status=${status}`,
    UPDATE_POSITION_TASK: (uuid: string) => `${prefixer}tasks/project?project_uuid=${uuid}`,

    TOGGLE_VISIBILITY: (task_uuid: string, user_uuid: string) => `${prefixer}tasks/${task_uuid}/${user_uuid}`,
    GET_TASK_GROUP_PROJECT: (site_uuid: string, project_uuid: string) => `${prefixer}tasks/groups/site/${site_uuid}/${project_uuid}`,
    GET_HIDE_TASK: (project_uuid: string, filter: FilterParams, keyword?: string, task_status?: string) => `${prefixer}tasks/project/masked?project_uuid=${project_uuid}&keyword=${keyword}&per_page=${filter.per_page || 20}&page=${filter.page || 1}&order=desc${task_status !== undefined ? `&task_status=${task_status}` : ""}`,
    GET_HIDE_GROUPS: (project_uuid: string, filter: FilterParams, keyword?: string) => `${prefixer}tasks/groups/project/masked?project_uuid=${project_uuid}&keyword=${keyword}&per_page=${filter.per_page || 20}&page=${filter.page || 1}&order=desc`,
    ASSIGN_TASK: `${prefixer}tasks/assign`,
    TOGGLE_GROUP_VISIBILITY: (task_uuid: string, user_uuid: string) => `${prefixer}tasks/groups/${task_uuid}/${user_uuid}`,
    MOVE_TASK_GROUP: (task_uuid: string, task_group_uuid: string, deleted_group: boolean) => `${prefixer}tasks/${task_uuid}/move/${task_group_uuid}?delete_task_group=${deleted_group}`,
    GET_FILTER_TASKS: (project_uuid: string) => `${prefixer}tasks/project/${project_uuid}`,
    UPDATE_MULTIPLE_TASK_DEADLINE:`${prefixer}tasks/updates`,
    TASK_DOCUMENT_EDIT:(uuid:string) => `${prefixer}tasks/document/edit/${uuid}`

}


export const GrantUrl = {
    GET_LOCKED_USERS: (p: FetchGrantUsersPayload) => `${prefixer}users/blocked?${getLockedUserEndpoint(p.target)}=${p.uuid}`,
    LOCK_OR_UNLOCK_USER: (target: FetchGrantUsersPayload['target'], uuid: string, user_uuid: string) => `${prefixer}${lockUserEndpoint(target)}/${uuid}/grant/${user_uuid}`
}

export const newsUrl = {
    READ_NEWS: (p: Pagination) => `${prefixer}users/articles/news?page=${p.page || 1}&keyword=${p.keyword || ''}&per_page=${p.per_page || 12}&order=desc`,
    READ_NEWS_LIST: (p: Pagination) => `${prefixer}users/articles/news/list?page=${1}&keyword=${p.keyword || ''}&per_page=${p.per_page || 12}&order=desc&category_ids=${p.category_ids || ''}`,
    READ_NEWS_LIST_DETAILS: (p: Pagination) => `${prefixer}users/articles/news/detail/${p.news_id}`,
}

export const trashcanUrls = {
    LIST_DELETED_USERS: (p: Pagination) => `${prefixer}clients/deleted?page=${p.page ?? 1}&keyword=${p.keyword || ''}&per_page=${p.per_page || 12}&order=desc`,
    RESTORE_OR_DELETE: `${prefixer}clients/restore`,
    LIST_DELETED_PROJECT: (p: Pagination) => `${prefixer}projects/deleted?page=${p.page ?? 1}&keyword=${p.keyword || ''}&per_page=${p.per_page || 12}&order=desc`,
    RESTORE_OR_DELETE_PROJECT: `${prefixer}projects/restore`,
}
export const User2FAUrls = {
    ACTIVATE_OR_DESACTIVATE_2FA_USER:`${prefixer}users/2fa`,
    ACTIVATE_2FA_USER:`${prefixer}users/2fa/confirm`,
    ACTIVATE_USER_EMAIL:`${prefixer}users/me`
}
