import {Locale} from 'moment';
import {Action} from 'redux';
import {TitleI18N,} from "./AccountTypes";
import {User} from '../types'
import {Role} from "./UserTypes";
import {Project, Task} from "./ProjectType";

//cli_export_interface_section
export const SET_GLOBAL_DATA = 'SET_GLOBAL_DATA';

export interface SetGlobalDataAction extends Action {
    type: typeof SET_GLOBAL_DATA,
    data: SetGlobalDataActionParams
}

export interface SetGlobalDataActionParams {
    data: GlobalDataState
}

export interface CategoriesWithSubCategory {
    sub?: Category[];
    category?: Category;
}

export interface Category {
    title?: TitleI18N;
    id?: number;
}


export interface GlobalDataState {
    roles?: Role[];
    status?: string[];
    sites?: Site[];
    groups?: Group[];
    questions?: Question[]
}

export interface Question {
    uuid?:          string;
    text?:          string;
    date_added?:    Date;
    date_modified?: Date;
}


export interface Group {
    ceo_name?: string;
    uuid?: string;
    name?: string;
    sites?: Site[];
    logo?: {
        file_name?: string,
        url?: string,
        size?: number
    }
}


export interface SiteData {
    data: Site[];
    total: number;
    pages: number;
    current_page: number;
}


export interface Site {
    uuid?: string;
    localisation?: string;
    date_added?: string;
    added_by_uuid?: string;
    name?: string;
    added_by?: User;
    modified_by?: User;
    users?: User[];
    projects?: Project[];
    clients?: Client[];
    projects_count?: number;
    clients_count?: number;
    group_uuid?: string;
    date_modified?: string;
    modified_by_uuid?: null;
}


export interface Client {
    internal_name?: string,
    official_name?: string,
    firstname?:string,
    lastname?:string,
    email?:string,
    site_uuid?: string,
    date_added?: string,
    date_modified?: string,
    name?:string,
    id?:string,
    site?: Site,
    users?: User[],
    added_by?: User,
    modified_by?: User,
    uuid?: string
    datev_id?: string,
    projects?: Project[]
    is_favorite?: boolean,
}

export interface InvitationInterface{
    uuid:string,
    status:string,
    project:Project,
    date_added:string,
    date_modified:string,
    user:User,
    invited_by:User
}


export interface Template {
    uuid?:string,
    name?:string,
    description?:string, 
    template_uuid?:string,
    tasks_templates?:Template[]
    task_group_template_uuid?:string,
    deadline?:string,
    position?:number,
    is_default?:boolean,
    parent?:Template,
    template?:Template,
    task_group_template?:Template,
    tasks_templates_groups?:Template[]
}
